import React from 'react'
import './HeaderFragment.scss'
import {Link} from 'react-router-dom'
import {LOGO} from '../../assets/images'

export class HeaderFragment extends React.Component {
    render() {
        return (
            <div className={"header"}>
                <Link to={'/'} >
                    <div className={"header__logo"}><img src={LOGO} alt='logo' /> <p>Closed Loop Medicine</p></div>
                </Link>
                <div className={"header__title"}>
                    <h1>Precision Dosing</h1>
                    <p>Apixaban Dose Calculator Demo</p>
                </div>
            </div>
        )
    }
}
