// src/components/DosageOverlay.tsx
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DosageOverlayProps {
    dosage: string;
    onClose: () => void;
}

export class DosageOverlay extends React.Component<DosageOverlayProps> {
    render = () => (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1200, // Ensure it is above other content
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    position: 'relative',
                    maxWidth: 400,
                    width: '100%',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Dosage Information
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {this.props.dosage} mg twice daily
                </Typography>
                <IconButton
                    size="small"
                    onClick={this.props.onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    );
}
