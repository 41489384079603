import React from 'react'
import './HomePage.scss'
import {DoseCalculatorForm, DoseCalculatorFormState} from "./HomePage/DoseCalculatorForm";
import {DosageOverlay} from "./HomePage/DosageOverlay";
import {Box} from "@mui/material";

interface HomeState {
    dose: string | undefined
}

export class HomePage extends React.Component<{}, HomeState> {
    constructor() {
        super({});

        this.state = {
            dose: undefined
        }
    }

    handleOnClose = () => {
        this.setState({
            dose: undefined
        })
    }

    calculateAge = (birthDate: Date): number => {
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the current month is before the birth month
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    handleFormSubmit = async (event: DoseCalculatorFormState) => {
        const message = {
            age: this.calculateAge(new Date(event.dateOfBirth)),
            sex: event.gender,
            weight: parseFloat(event.weight),
            isComed: event.takingMedication === "Yes",
            cCRCL: parseFloat(event.cCRCL),
            treatmentPhase: event.stageOfTreatment.replace(" ", "").toLowerCase(),
            isAsian: event.ethnicity === "Asian or British Asian",
        }

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        const result = await fetch("https://tayld0rew0.execute-api.eu-west-2.amazonaws.com/v1/executeSageMakerEndpoint", {
            method: "POST",
            body: JSON.stringify(message),
            headers: headers
        }).then(
            response => {
                console.log(response)
                return response
            }
        ).then(
            response => response.json()
        ).then(
            // 1 d.p
            dose => (Math.round(parseFloat(dose) * 10) / 10)
        )

        this.setState({
            dose: `${result}`
        })
    }

    render() {
        return (
            <div className={"page"}>
                {this.state.dose && (<DosageOverlay dosage={this.state.dose} onClose={this.handleOnClose}/>)}
                <Box
                    component="text"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        maxWidth: 600,
                        margin: 'auto',
                        marginTop: 4,
                        border: '3px solid #00263E;',
                        marginBottom: 4,
                        padding: 1,
                        borderRadius: 2,
                        boxShadow: 3,
                        backgroundColor: 'rgba(0, 38, 62, 0.2)',
                    }}
                >
                    <p className={'info__heading'}>Description</p>
                    <p className={'info__description'}>CLM produced AI/ML model trained on data sets produced using
                        Pharmacokinetic/Pharmacodynamic (PKPD) modelling.</p>
                    <p className={'info__description'}>This dose calculator can be used to calculate the optimal dose of
                        Apixaban for an individual patient for the treatment of venous thromboembolism, which consists
                        of three treatment stages. The first stage (Treatment) covers the first 7 days following the VTE
                        event. The second stage (Prophylaxis 1), for the prevention of recurrent VTE, runs from day 8
                        until at least 6 months. The third stage (Prophylaxis 2), also for the prevention of recurrent
                        VTE, is initiated after at least 6 months.</p>
                </Box>
                <DoseCalculatorForm onSubmit={this.handleFormSubmit}/>

                <Box
                    component="text"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 600,
                        margin: 'auto',
                        marginTop: 4,
                        border: '1px solid #00263E;',
                        marginBottom: 4,
                        padding: 1,
                        borderRadius: 2,
                        boxShadow: 3,
                        backgroundColor: 'rgba(0, 38, 62, 0.2)',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className={'info__disclaimer__heading'}>Disclaimer:</p>
                    <p className={'info__disclaimer'}><span>This product is a <b>prototype for demonstration purposes only.</b> It is not intended for use as a medical device and should not be relied upon for diagnosing, treating, curing, or preventing any medical condition. Always consult with a qualified healthcare professional for medical advice and treatment.
                    </span></p>
                </Box>
            </div>
        )
    }
}
