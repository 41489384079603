import React from 'react'
import './FooterFragment.scss'

export class FooterFragment extends React.Component {
    render() {
        const applicationData = require('../../app.json')

        return (
            <div className={"footer"}>
                v{applicationData.version}
            </div>
        )
    }
}
