import React from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';

interface DoseCalculatorFormProps {
    onSubmit?: (data: DoseCalculatorFormState) => void;
}

export interface DoseCalculatorFormState {
    dateOfBirth: string,
    gender: string,
    weight: string,
    takingMedication: string,
    cCRCL: string,
    stageOfTreatment: string,
    ethnicity: string,
}

export interface DoseCalculatorFormErrorState {
    dateOfBirth: string,
    gender: string,
    weight: string,
    takingMedication: string,
    cCRCL: string,
    stageOfTreatment: string,
    ethnicity: string,
}


class DoseCalculatorForm extends React.Component<DoseCalculatorFormProps, {
    data: DoseCalculatorFormState,
    errors: DoseCalculatorFormErrorState
}> {
    constructor(props: DoseCalculatorFormProps) {
        super(props);

        this.state = {
            data: {
                dateOfBirth: '',
                gender: '',
                weight: '',
                takingMedication: '',
                cCRCL: '',
                stageOfTreatment: '',
                ethnicity: '',
            },
            errors: {
                dateOfBirth: '',
                gender: '',
                weight: '',
                takingMedication: '',
                cCRCL: '',
                stageOfTreatment: '',
                ethnicity: '',
            }
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {
        name?: string;
        value: unknown
    }>) => {
        const {name, value} = e.target;
        this.setState({
            data: {
                ...this.state.data,
                [name as string]: value,
            },
            errors: {
                ...this.state.errors,
                [name as string]: '',
            }
        });
    };

    handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>, name: string) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: e.target.value as string,
            }
        })
    };

    validateForm = () => {
        let isValid = true;
        const newErrors = {
            dateOfBirth: '',
            gender: '',
            weight: '',
            takingMedication: '',
            cCRCL: '',
            stageOfTreatment: '',
            ethnicity: '',
        };

        const formData = this.state.data

        if (!formData.dateOfBirth) {
            newErrors.dateOfBirth = 'Date of Birth is required';
            isValid = false;
        }

        if (!formData.gender) {
            newErrors.gender = 'Gender is required';
            isValid = false;
        }

        if (!formData.weight || isNaN(Number(formData.weight))) {
            newErrors.weight = 'Weight is required and must be a number';
            isValid = false;
        } else {
            const numericWeight = parseFloat(formData.weight)
            if (numericWeight < 20 || numericWeight > 1000) {
                newErrors.weight = 'Weight must be between 20kg and 1000kg';
            }
        }

        if (!formData.takingMedication) {
            newErrors.takingMedication = 'Medication status is required';
            isValid = false;
        }

        if (!formData.cCRCL || isNaN(Number(formData.cCRCL))) {
            newErrors.cCRCL = 'cCRCL is required and must be a number';
            isValid = false;
        } else {
            const numericValue = parseFloat(formData.cCRCL)
            if (numericValue <= 0 || numericValue > 250) {
                newErrors.cCRCL = 'cCRCL must be value greater than 0 and less or equal to 250 mL/min';
            }
        }

        if (!formData.stageOfTreatment) {
            newErrors.stageOfTreatment = 'Stage of Treatment is required';
            isValid = false;
        }

        if (!formData.ethnicity) {
            newErrors.ethnicity = 'Ethnicity is required';
            isValid = false;
        }

        this.setState({
            errors: newErrors
        });

        return isValid;
    };

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.validateForm()) {
            console.log(this.state.data);
            if (!!this.props.onSubmit) {
                this.props.onSubmit(this.state.data)
            }
        }
    };

    render = () => {
        const outlinedStyle = {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'gray', // Default border color
                },
                '&:hover fieldset': {
                    borderColor: '#00263E', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#00B8B4', // Border color when focused
                },
                '&.Mui-error fieldset': {
                    borderColor: 'red', // Border color when error exists
                },
            },
            '& .MuiInputLabel-root': {
                color: 'gray', // Default label color
                '&.Mui-focused': {
                    color: '#00B8B4', // Label color when focused
                },
                '&.Mui-error': {
                    color: 'red', // Label color when error exists
                },
            },
        }
        return (
            <Box
                component="form"
                onSubmit={this.handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    maxWidth: 600,
                    margin: 'auto',
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: 'background.paper',
                }}
            >
                <Typography variant="h5" component="h2" gutterBottom>
                    Dose Calculator Inputs
                </Typography>

                <FormControl variant="outlined" fullWidth error={!!this.state.errors.gender} sx={outlinedStyle}>
                    <InputLabel>Sex</InputLabel>
                    <Select name="gender"
                            value={this.state.data.gender}
                            onChange={(e: any) => this.handleSelectChange(e, 'gender')}
                            label="Sex" variant={'outlined'}>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                    </Select>
                    <FormHelperText>{this.state.errors.gender}</FormHelperText>
                </FormControl>

                <TextField  sx={outlinedStyle}
                    label="Date of Birth"
                    name="dateOfBirth"
                    type="date"
                    value={this.state.data.dateOfBirth}
                    onChange={this.handleChange}
                    InputLabelProps={{shrink: true}}
                    error={!!this.state.errors.dateOfBirth}
                    helperText={this.state.errors.dateOfBirth}
                    variant="outlined"
                    fullWidth
                />

                <TextField  sx={outlinedStyle}
                    label="Weight (kg)"
                    name="weight"
                    type="number"
                    value={this.state.data.weight}
                    onChange={this.handleChange}
                    error={!!this.state.errors.weight}
                    helperText={this.state.errors.weight}
                    variant="outlined"
                    fullWidth
                />

                <TextField  sx={outlinedStyle}
                    label="Creatinine clearance (mL/min)"
                    name="cCRCL"
                    type="number"
                    value={this.state.data.cCRCL}
                    onChange={this.handleChange}
                    error={!!this.state.errors.cCRCL}
                    helperText={this.state.errors.cCRCL}
                    variant="outlined"
                    fullWidth
                />

                <FormControl variant="outlined" fullWidth error={!!this.state.errors.stageOfTreatment}  sx={outlinedStyle}>
                    <InputLabel>Stage of Treatment</InputLabel>
                    <Select
                        name="stageOfTreatment"
                        value={this.state.data.stageOfTreatment}
                        onChange={(e: any) => this.handleSelectChange(e, 'stageOfTreatment')}
                        label="Stage of Treatment"
                    >
                        <MenuItem value="Treatment">Treatment (first 7 days)</MenuItem>
                        <MenuItem value="Prophylaxis 1">Prophylaxis 1 (Day 8 to at least 6 months)</MenuItem>
                        <MenuItem value="Prophylaxis 2">Prophylaxis 2 (after at least 6 months)</MenuItem>
                    </Select>
                    <FormHelperText>{this.state.errors.stageOfTreatment}</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" fullWidth error={!!this.state.errors.ethnicity}  sx={outlinedStyle}>
                    <InputLabel>Ethnicity</InputLabel>
                    <Select
                        name="ethnicity"
                        value={this.state.data.ethnicity}
                        onChange={(e: any) => this.handleSelectChange(e, 'ethnicity')}
                        label="Ethnicity"
                    >
                        <MenuItem value="Asian or British Asian">Asian or British Asian</MenuItem>
                        <MenuItem value="White">White</MenuItem>
                        <MenuItem value="Mixed or multiple ethnic groups">Mixed or multiple ethnic groups</MenuItem>
                        <MenuItem value="Black, Black British, Caribbean or African">Black, Black British, Caribbean or
                            African</MenuItem>
                    </Select>
                    <FormHelperText>{this.state.errors.ethnicity}</FormHelperText>
                </FormControl>


                <FormControl component="fieldset" error={!!this.state.errors.takingMedication}  sx={outlinedStyle}>
                    <Typography variant="subtitle1">Comedications*?</Typography>
                    <RadioGroup                         name="takingMedication"
                        value={this.state.data.takingMedication}
                        onChange={this.handleChange}
                    >
                        <FormControlLabel value="yes" control={<Radio sx={{
                            color: 'defaultColor', // Default color
                            '&.Mui-checked': {
                                color: '#00B8B4', // Color when selected
                            },
                        }}/>} label="Yes"/>
                        <FormControlLabel value="no" control={<Radio sx={{
                            color: 'defaultColor', // Default color
                            '&.Mui-checked': {
                                color: '#00B8B4', // Color when selected
                            },
                        }}/>} label="No"/>
                    </RadioGroup>
                    <FormHelperText>{this.state.errors.takingMedication}</FormHelperText>
                </FormControl>
                <Typography variant={"caption"}>*Strong or moderate CYP3A4 or P-glycoprotein inhibitors</Typography>
                <Button variant="contained" color="primary" type="submit"   sx={{
                    backgroundColor: '#00263E', // Background color
                    '&:hover': {
                        backgroundColor: '#00B8B4', // Hover color
                    },
                }}>
                    Calculate
                </Button>
            </Box>
        );
    }
}

export {DoseCalculatorForm};