import './App.scss'
import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import {HeaderFragment} from './components/fragments/HeaderFragment'
import {FooterFragment} from './components/fragments/FooterFragment'
import {HomePage} from './components/pages/HomePage'
import {NotFoundPage} from './components/pages/NotFoundPage'

export default class App extends React.Component {
    render() {
        return (
            <div>
                <Router>
                    <HeaderFragment />
                    <div>
                        <Routes>
                            <Route element={<HomePage />} path='/' />
                            <Route element={<NotFoundPage />} path='*' />
                        </Routes>
                    </div>
                    <FooterFragment />
                </Router>
            </div>
        )
    }
}
