import React from 'react'
import './NotFoundPage.scss'

export class NotFoundPage extends React.Component {
    render() {


        return (
            <div className={"page"}>
                <p>You are not supposed to find this page</p>
            </div>
        )
    }
}
